.dropzone {
  padding: 10px 0;
}

.dropzone__file-list {
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }
}
