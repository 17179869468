.TemplatesEdit {
  .emailInput {
    margin-bottom: 0px;
  }
  .selectInputPaddingRight{
    padding-right: 1rem;
  }
  .selectWidth{
    width: 20rem;
    margin-bottom: 20px;
  }
}

.errorBorder {
  border-radius: 12px;
  border:2px solid #FF0000;
}