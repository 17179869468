.pagination {
  background-color: $white;
  justify-content: flex-end;
  padding: 14px 0;
  border-radius: 0;
  align-self: center;

  button {
    border: none;
    color: $vfBlue;
    background: transparent;
    font-weight: bold;
    padding: 0;
    z-index: 100;

    &:first-of-type {
      margin-right: 40px;
    }

    &:last-of-type {
      margin-left: 40px;
    }

    &:disabled {
      opacity: .25;
    }

    &:hover,
    &:active,
    &:focus {
      color: $vfLinkActive;
      outline: none;
    }
  }

  &__items {
    display: flex;

    .page-item {
      
      &:not(:last-of-type) {
        margin-right: 10px;
      }

      .page-link {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        font-weight: bold;
        color: #2D2D2D;
        padding: 0;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $white;
          background-color: $vfBlue;
        }
      }

      &.disabled {
        .page-link {
          color: $vfFunctionalGray;
        }
      }

      &.active {
        .page-link {
          color: $white;
          background-color: $vfBlue;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.page-size-picker {
  display: flex;
  align-items: center;
  padding: 14px;

  &__text {
    font-weight: bold;
    color: $blue-700;
  }

  .dropdown {
    .dropdown-toggle {
      min-width: 80px;
      border-radius: 10px;
      height: 40px;
      line-height: 0;
      margin-left: 20px;
    }

    .dropdown-item {
      line-height: 2;
    }
  }
  
}

.paging {
  display: flex;
  background: $white;
  position: sticky;
  bottom: 0;
  margin-bottom: 16px;

  .page-size-picker {
    width: 50%;
  }

  .pagination {
    margin-bottom: 0;
    width: 50%;
  }
}
