html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  color: #2d2926;

  * {
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      
    }
   
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
   
    &::-webkit-scrollbar-thumb {
      background-color: $vfPink;
      border-radius: 10px;
    }
  }
}

.light-text {
  color: white;
}

.loading-text {
  color: $vfPink;
  font-weight: bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Roboto', sans-serif;
  color: #2d2926;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 900;
}

h5,
.h5,
h6,
.h6 {
  font-weight: 700;
}

.display-1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 5rem;
  line-height: 5.625rem;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 1.5rem;
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 1.2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
  line-height: 1.2rem;
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.2rem;
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.2rem;
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.2rem;
}

.label {
  font-size: 0.75rem;
  line-height: 1.2rem;
  color: #6c757d;

  &.light-text {
    color: rgba(255, 255, 255, 0.6);
  }
}
