.autosaving-draft {
    position: fixed;
    right: 30px;
    top: 30px;
    color: var(--green);
    display: flex;
    align-items: flex-start;
    z-index: 100;
    padding: 0 20px;

    svg {
        font-size: 40px;
    }

    span {
        font-weight: 700;
        display: block;
        line-height: 20px;   
    }
}