.emoji-picker {
    &__button {
        padding: 0;
        border: 0;
        background: transparent;

        svg {
            fill: $gray-300;
            width: 24px;
            height: 24px;
            &.active {
                fill: $vfBlue;
            }
        }
    }

    &__popup {
        box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.25);
        border-radius: 5px;
        background: $white;
        z-index: 10;
        width: 333px;
        height: 200px;
        padding: 4px 20px 8px 4px;

        &__loading {
            grid-column: span 8 / span 8;
            width: 20px;
            height: 20px;
            margin: 0 auto;
        }

        &__input {
            margin-bottom: 6px;

            input {
                padding-left: 32px;
                height: 32px;
                padding-top: 0;
                padding-bottom: 0;
            }
            

            .Input__icon {
                position: absolute;
                left: 8px;
                top: 50%;
                transform: translateY(-50%);
                height: 18px;
                width: 18px;
                pointer-events: none;

                path {
                    fill: $gray-300;
                }
            }
        }

        &__items {
            max-height: 143px;
            margin-right: -20px;
            display: grid;
            grid-template-columns: repeat(8, minmax(0, 1fr));
            overflow-y: auto;
            padding-right: 20px;
            gap: 8px;
        }

        &__item {
            padding: 0;
            border: 0;
            line-height: 32px;
            font-size: 1.875rem; /* 30px */
            background: $white;
        }
    }
}