@mixin button-with-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: $white;
        width: 16px;
        height: 16px;
        margin-left: 8px;
    }
}

@mixin alert-with-info {
    .alert {
        display: flex;

        svg {
            font-size: 20px;
            position: relative;
            bottom: -2px;
        }
    }
}

#broadcast-form {
    .vertical-divider {
        display: none;
        border: 0;
        height: auto;
        align-self: stretch;
        background: #E1E6EF;
        width: 2px;
        margin: 0 17px;
    }

    .personalization-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: -24px;
        z-index: 1;
        position: relative;

        .content-length {
            margin-bottom: 8px;
        }
    }

    .DistributionList {
        border: 0;

        & > .card-body {
            padding: 0;
        }

        .GroupsAndProfilesCard {
            border: 0;

            & > .card-body {
                padding: 0;
            }
        }

        .GroupsAndProfilesCol, .MessageRecipientsCol {
            flex: 0 0 50%;
            max-width: 50%;
        }
        
    }

    #schedule-and-send-btn, #send-now-btn {
        min-width: 0;
        padding-left: 16px;
        padding-right: 16px;
        height: 48px;
    }

    #send-now-btn {
        @include button-with-arrow;
    }

    @include alert-with-info;

    @include media-breakpoint-up(lg) {
        .left-half, .right-half {
            max-width: calc(50% - 18px);
        }

        .vertical-divider {
            display: block;
        }

    }
    
}


.BroadcastScheduleModal {
    .Date-Input {
        width: 100%;
    }

    .rc-time-picker {
        margin-left: 0;
        .rc-time-picker-input {
            padding-left: 15px;
        }
    }

    #schedule-and-send-submit-btn {
        height: 48px;
        @include button-with-arrow;
    }

    @include alert-with-info;
}


