.connectorRoot:before {
    font-family: 'Font Awesome 5 Free';
    color:#000;
    content: '\f30b';
    font-weight: 900;
    font-size:60px;
    display: table;
    margin: 0 auto;
}

.connectorLine {
    display: none!important;
}

.stepLabel svg.activeColor {
    color: $vfBlue!important;
}

.stepLabel svg {
    color:$vfBlue!important;
}

.activeLabel {
    font-weight: 900!important;
}

.entitylabel-title {
    position: relative;
    display: inline-block;
    height: 28px;
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
}

.entitylabels-card div {
    padding: 7px 10px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 4px;
}

.btn-addlabel {
    margin-top: 10px;
    width: 120px!important;
    height:40px;
}
