.btn {
  height: 40px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: $btn-border-radius-sm;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  white-space: nowrap;
  
  &:not(.btn-link) {
    min-width: 190px;
  }

  &.btn-medium {
    min-width: 150px;
  }

  &.btn-abandon {
    min-width: 160px;
  }

  &:disabled {
    opacity: 1;
  }

  &.btn-sm {
    height: 40px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: $btn-border-radius-sm;
  }

  &.btn-md {
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: $btn-border-radius;
  }

  &.btn-lg {
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: $btn-border-radius-lg;
    font-size: 1rem;
  }
  
  &.square {
    border-radius: $btn-square-border-radius;
    min-width: 157px;
  }

  &.btn-cancel {
    min-width: 50px;
  }

  &:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  
  
  

  &.btn-primary {
    background: $vfBlue;
    border: 2px solid $vfBlue;
    font-weight: bold;

    &:not(:disabled):not(.disabled).active {
      border-color: $vfBlue;
      background-color: $vfBlue;
    }

    &:disabled {
      border-color: mix($vfBlue, $black, $weight: 70%);
      background-color: mix($vfBlue, $black, $weight: 70%);
      color: mix($white, $black, $weight: 70%);
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      box-shadow: 0px 3px 6px rgba($vfBlue, .6);
    }

    &:not(:disabled):not(.disabled):hover {
      border-color: $vfBlue;
      background-color: $vfBlue;
    }
    
    &:not(:disabled):not(.disabled):active {
      background-color: $blue-700;
      border-color: $blue-700;
    }
  }
  
  &.btn-secondary {
    background: transparent;
    border: 2px solid $vfBlue;
    font-weight: bold;
    color: $vfBlue;

    &:not(:disabled):not(.disabled).active {
      border-color: $vfBlue;
      background-color: transparent;
      color: $vfBlue;
    }
  
    &:disabled {
      border-color: mix($vfBlue, $black, $weight: 70%);
      background: mix(transparent, $black, $weight: 70%);
      color: mix($vfBlue, $black, $weight: 70%);
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      color: $vfBlue;
      background-color: transparent;
      border-color: $vfBlue;
      box-shadow: 0px 3px 6px rgba($vfBlue, .6);
    }
  }

  &.btn-primary-dynamic {
    --btn-primary-dynamic-color: #0F4CDB;
    transition: none;
    background: var(--btn-primary-dynamic-color);
    font-weight: bold;
    color: #fff;

    &:disabled {
      filter: brightness(0.7);
    }

    &:not(:disabled):active {
      filter: brightness(0.8);
    }

    &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):active:focus {
      box-shadow: 0px 3px 7px -2px var(--btn-primary-dynamic-color);
    }
  }

  &.btn-secondary-dynamic {
    --btn-secondary-dynamic-color: #0F4CDB;
    transition: none;
    background: transparent;
    font-weight: bold;
    color: var(--btn-secondary-dynamic-color);
    border: 2px solid var(--btn-secondary-dynamic-color);

    &:disabled {
      filter: brightness(0.7);
    }

    &:not(:disabled):active {
      filter: brightness(0.8);
    }

    &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):active:focus {
      box-shadow: 0px 3px 7px -2px var(--btn-secondary-dynamic-color);
    }
  }
  
  &.btn-link {
    border: 0;
    background: transparent;
    color: $btn-link-color;
  
    &:disabled {
      color: $btn-link-disabled-color;
      opacity: 0.75;
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      color: $btn-link-hover-color;
    }
  }
  
  &.btn-warning {
    border: 2px solid $vfPink;
    background: $vfPink;
    font-weight: bold;
    color: $white;

    &:not(:disabled):not(.disabled).active {
      border-color: $vfPink;
      background-color: $vfPink;
      color: $white;
    }

    &:disabled {
      border-color: mix($vfPink, $black, $weight: 70%);
      background-color: mix($vfPink, $black, $weight: 70%);
      color: mix($white, $black, $weight: 70%);
    }
  
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      box-shadow: 0px 3px 6px rgba($vfPink, .6);
      color: $white;
    }

    &:not(:disabled):not(.disabled):hover {
      background-color: $vfPink;
      border-color: $vfPink;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $pink-700;
      border-color: $pink-700;
    }
  }

  &.btn-purple {
    border: 2px solid $vfPurple;
    background: $vfPurple;
    font-weight: bold;
    color: $white;

    &:not(:disabled):not(.disabled).active {
      border-color: $vfPurple;
      background-color: $vfPurple;
      color: $white;
    }

    &:disabled {
      border-color: mix($vfPink, $black, $weight: 70%);
      background-color: mix($vfPurple, $black, $weight: 70%);
      color: mix($white, $black, $weight: 70%);
    }
  
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      box-shadow: 0px 3px 6px rgba($vfPurple, .6);
      color: $white;
    }

    &:not(:disabled):not(.disabled):hover {
      background-color: $vfPurple;
      border-color: $vfPurple;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $purple-700;
      border-color: $purple-700;
    }
  }

  &.btn-success {
    font-weight: bold;

    &:disabled {
      opacity: .65;
    }
  }

}

.show > .btn.dropdown-toggle {
  &.btn-primary, &.btn-primary:focus {
    background-color: $blue-700;
    border-color: $blue-700;
  }

  &.btn-primary:focus {
    box-shadow: 0px 3px 6px rgba($vfBlue, .6);
  }

  &.btn-secondary, &.btn-secondary:focus {
    color: $vfBlue;
    background-color: transparent;
    border-color: $vfBlue;
  }

  &.btn-secondary:focus {
    box-shadow: 0px 3px 6px rgba($vfBlue, .6);
  }

  &.btn-warning, &.btn-warning:focus {
    background-color: $pink-700;
    color: $white;
    border-color: $pink-700;
  }

  &.btn-warning:focus {
    box-shadow: 0px 3px 6px rgba($vfPink, .6);
  }
}

button.tab-control {
  border: 0;
  background: transparent;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;

  &:not(.active) {
    color: $vfBlue;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.75;
  }
}

.btn-icon {
  padding: 0;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    height: 12px !important;
    margin-left: -1px;
  }

  &.dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.list-group
{
  .list-group-item:nth-of-type(odd) {
    background-color: #F5F6F8;
  
    &:hover {
      background-color: rgba(0, 97, 115, 0.1);
    }
  }
  
  .list-group-item:nth-of-type(even) {
    background-color: darken(#F5F6F8, 5);
  
    &:hover {
      background-color: rgba(0, 97, 115, 0.1);
    }
  }
}

.btn-group:not(.dropdown) {
  & > .btn {
    border-width: 1px;
    height: 44px;
    
    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):active {
      box-shadow: none;
    }

    &:not(.active):not(:active):not(:focus):not(:hover) {
      background: $white;
      color: $vfBlue;
    }

  }
}

.button-group {
  display: flex;
  align-items: center;
  height: 36px;

  & > .btn {
    min-width: auto;
    padding: 0 20px;
    color: $gray-300;
    height: 36px;
    font-size: 1rem;

    &.active {
      background-color: $vfBlue;
      color: $white;
    }
  }
}

.btn-group.dropdown {
  & > .btn {
    &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):active {
      box-shadow: 0px 3px 6px rgba($vfBlue, .6);
    }

    &.btn-primary {
      &:not(:disabled):not(.disabled):hover {
        border-color: $blue-700;
        background-color: $blue-700;
      }
    }

    &:not(.dropdown-toggle) {
      min-width: 145px;
      padding-left: 56px;
    }

    &.dropdown-toggle-split {
      min-width: 0;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background: $vfBlue;
  }
}

#send-options-button {
  padding-left: 20px;
  padding-right: 20px;

  &::after {
    margin-left: 5px;
  }

  &.isLoading{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    &::after {
      display: none;
    }
  }

  .spinner-border {
    flex-shrink: 0;
  }
}