.profile-customize-modal {
    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__switch {
        margin-right: 12px;
    }

    &__list {
        list-style-type: none;
        padding: 0;

        &__item {
            padding: 4px 0;
            display: flex;
            align-items: center;

            &__draggable {
                margin-right: 10px;
                cursor: move;
            }
        }
    }
}