.Table {
  position: relative;
  width: auto;
  overflow-x: auto;
  // Margin/padding hack for action menu overflow
  // padding-bottom: 100px;
  // margin-bottom: -100px;
  background: $white;

  table {
    margin-bottom: 0;

    thead {
      th {
        &.check {
          width: 38px;
          vertical-align: middle;
        }

        &.sortable {
          cursor: pointer;

          span {
            &:after {
              display: inline-block;
              content: '';
              height: 12px;
              width: 7px;
              margin-left: 5px;
              background-image: url('../images/sortable.svg');
              background-repeat: no-repeat;
            }
          }

          &.asc {
            span {
              &:after {
                height: 7px;
                background-image: url('../images/sortable-asc.svg');
              }
            }
          }

          &.desc {
            span {
              &:after {
                height: 7px;
                background-image: url('../images/sortable-desc.svg');
              }
            }
          }
        }
      }
    }

    tr {
      &.selected {
        background-color: #DCE7EB !important;
      }
    }

    th, td {
      white-space: nowrap;
    }

    td.actions {
      position: relative;
      text-align: center;
      width: 72px;
      
      background-position: center center;
      background-size: 4px 18px;

      &:hover {
        cursor: pointer;
      }

      div > button, div > a {
        padding: 0;
        border: 0;
      }

      div > a {
        padding: 0;
        border: 0;
      }

      .actions-menu {
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        z-index: 110;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          border-radius: 5px;
          background-color: white;

          li, a {
            display: block;
            color: $vfBlue;
            font-weight: bold;

            &:hover {
              background-color: #E2E3E6;
              text-decoration: underline;
            }
          }

          button {
            width: 100%;
            border: none;
            background: transparent;
            font-weight: bold;
            color: $vfBlue;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .dropdown-menu > .dropdown-item{
        padding: 0.25rem 1.5rem;
      }
    }

    .Table__Loading {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 21px;

      .spinner-border {
        color: $vfPink;
        margin-right: 16px;
        height: 24px;
        width: 24px;
      }
    }
    
  }

  &__horizontal-scrolltrack {
    bottom: 0;
    border-radius: 10px;
  }

  &__vertical-scrolltrack {
    bottom: 0;
    top: 0;
    right: 0;
    border-radius: 10px;
  }

  &__vertical-scrollthumb {
    background: $vfPink;
    border-radius: inherit;
  }

  &__horizontal-scrollthumb {
    background: $vfPink;
    border-radius: inherit;
  }
}

.delete-action {
  color: $vfRed;

  &:visited {
    color: $vfRed;
  }

  &:hover {
    color: $red-700;
  }
}
