.overflow-visible {
  overflow: visible !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.no-overflow-x{
  overflow-x:hidden!important;
}

.hidden {
  display: none !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.flex-direction-row {
  flex-direction: row;
}

.questions-card {
  max-width: 50%;
  min-width: 30rem;
}