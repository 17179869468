.listGroup {
    position: relative;
    width: auto;
    overflow: auto;
    max-height: 50vh;
}
.listGroupMain{
    position: relative;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 78vh;
}
.listGroupMedium {
    position: relative;
    width: auto;
    overflow-y: scroll;
    max-height: 42vh;
}
.listGroupMainMedium {
    position: relative;
    width: auto;
    overflow: auto;
    max-height: 74vh;//the available profiles list define height=74vh
}
.list-group-item.active {
background-color: lightgrey !important;
border-color: lightgrey !important; }

.blockDiv{
    display: inline-block;
    width:50%;
}

.modifyGroup{
    float: right;
    padding-left: 2%;
}
.addSubGroup{
    float: right;
    padding-right: 2%;
}
.groupMemberCard{
    height:50%;
}
.groupCard{
    border-radius: 0px !important;
    overflow-x: auto !important;
}
.groupWrapper{
    min-width: auto;
}
.subGroupListGroupItem{
    background-color:#f0f8ff !important;
    vertical-align: middle !important;
    padding-top:0px !important;
    padding-bottom:0px !important;
    border-radius: 0 !important;
    white-space: nowrap;
}
.groupListGroupItem{
    vertical-align: middle !important;
    padding-top:0px !important;
    padding-bottom:0px !important;
    border-radius: 0 !important;
}
.groupHeader{
    background-color: $vfBlue !important;
    color: #FFFFFF;
}
.activeGroupToggle{
    background-color: $group-table-selected-color !important;
}
.activeSubGroupToggle{
    background-color:#bde0ff !important;
}
.groupItemIcon{
    margin-top:6px;
}
.groupFilter {
    width:95%;
    margin: 3%;
}
.evenGroupItem {
    background-color: darken(#F5F6F8, 5) !important;
}
.oddGroupItem {
    background-color: #F5F6F8 !important;
}

.evenGroupItem, .oddGroupItem {
    &.selected {
        background-color: $vfBlue !important;
        color: $white;
    }
}

.groupMemberItem {
    background-color: darken(#F5F6F8, 15) !important;
}

.consist-height {
    height: 41px;
    vertical-align: middle;
    padding: 10px 0;
}

.btn-xs{
    height:20px;
    padding:0 10px!important;
    font-size:10px!important;
}

.model-20w{
    width: 400px!important;
    min-width:400px!important;
}

.filter-button {
    font-size: 1rem;
    background-color: $vfBlue;
    color: white;
    border: 0;
    height:30px;
    border-radius: 5px;

    &.active,
    &:hover,
    &:disabled {
      background-color: $vfLinkActive;
    }
  }
.modal-buttons{
    justify-content: center;
}

.members-container{
    padding: 20px;
    margin-top:60px;
    margin-left: 10px;
    border-radius: 10px;
    height: 85vh;
}