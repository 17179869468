$group-table-selected-color: #DCE7EB;

.tableListItem{
  vertical-align: middle !important;
  padding-top:0px !important;
  padding-bottom:0px !important;
  border-radius: 0 !important;
}

.table {
  thead {
    th {
      background-color: white;
      border-bottom: 1px solid $vfFunctionalGray;
      border-top: none;
      font-size: 1rem;
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  tbody {
    tr {
      background-color: $white;
            
      // &:hover {
      //   background-color: darken(#F5F6F8, 10);
      // }

      td {
        border-bottom: 1px solid $vfFunctionalGray;
        border-right: 1px solid rgba(#707070, .25);
        padding: 10px;
        height: 50px;
        vertical-align: middle;

        &.cell--select {
          border-right-color: $white;
        }
        
        .fa-stack {
          height: 1.2em!important;
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: #F5F6F8;

        &:hover {
          background-color: rgba(0, 97, 115, 0.1);
        }
      }

      tr:nth-of-type(even) {
        background-color: darken(#F5F6F8, 5);

        &:hover {
          background-color: rgba(0, 97, 115, 0.1);
        }
      }
    }
  }

}
