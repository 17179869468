@mixin header-bar {
    .header-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    
        h1 {
          line-height: 1em;
          margin-top: -0.5rem;
          margin-bottom: 0;
        }
    
        .actions {
          a,
          button {
            margin-right: 25px;
    
            &:last-child {
              margin-right: 0;
            }
          }
        }
    }
}