.LoginPage {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    @include media-breakpoint-down(xs) {
        padding: 0 15px;
    }

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
        }
    }

    &__side {
        background: #F2F2F2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-left: 20px;
        padding-right: 35px;
    }

    &__form-box {
        max-width: 528px;
        min-width: 425px;
        width: 33.33%;
        box-shadow: 0px 3px 6px #00000066;
        border-radius: 6px;
        padding: 36.5px 50px 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        z-index: 1;
        background: $white;
        position: relative;
        transform: translateX(15%);


        @include media-breakpoint-down(md) {
            transform: none;
        }

        @include media-breakpoint-down(xs) {
            min-width: 100%;
        }

        &__logo {
            height: 85px;
            display: block;
            margin: 0 auto;            
            max-width: 100%;
        }

        &__link {
            font-size: 12px;
            line-height: 16px;
            display: block;
            margin-bottom: 1rem;
            width: fit-content;
        }

        &__checkbox {
            color: #2D2926;
            font-size: 14px;
        }

        &__label {
            color: #2D2926;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;
        }

        &__message-container {
            min-height: 20px;

            .errors {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__support-box {
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
        z-index: 1;
        position: relative;
        margin-bottom: 145px;
        transform: translateX(15%);

        @media only screen and (max-height: 1074px) {
            margin-bottom: 8vh;
        }

        @include media-breakpoint-down(md) {
            transform: none;
        }

        &__link {
            font-size: 14px;
            line-height: 19px;

            &:not(:hover):not(:active) {
                text-decoration: none;
            }
        }

        &__text {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 19px;
            color: #6C6C71;
        }
        
    }

    &__illustration-container {
        width: 100%;
        position: absolute;
        bottom: 300px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        @media only screen and (max-height: 1074px) {
            bottom: calc(8vh + 155px);
        }

        svg {
            max-width: 100%;
            width: 1500px;
            display: block;
            margin: 0 auto;
        }
    }
}

