.view-labels-modal, .create-labels-modal, .edit-labels-modal {
    .modal-content {
        padding: 24px 24px 24px 32px;
    }
    .modal-header {
        align-items: center;
        justify-content: left!important;
    }
    .modal-title {
        margin-left: 12px;
        font-size: 28px;
        font-weight: 600;
    }

    .modal-body {
        padding: 15px 0 15px 0;
        max-height: 445px;

    }

    .modal-footer {
        padding: 0;
        padding-top: 28px;
    }

    .label-group {
        font-size: 1rem;
        line-height: 20px;
        font-weight: 800;
        color: #252733;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    label {
        font-size: 1rem;
        line-height: 20px;
        font-weight: 800;
        color: #252733;
    }

    .label-text {
        font-size: 0.9rem;
        line-height: 30px;
        font-weight: 500;
        color: #252733;
        margin: 0 0 0.5rem 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 400px;
        white-space: nowrap;

        // workaround to prevent Safari from showing tooltip when text overflow is hidden with ellipsis
        &::after {
            content: '';
            display: block;
          }
    }

    .label-edit {
        font-size: 0.9rem;
        line-height: 20px;
        font-weight: 500;
        color: #0F4CDB;
    }

    .label-delete {
        font-size: 0.9rem;
        line-height: 20px;
        font-weight: 500;
        color: #FF7052;
    }

    .label-unmatch{
        font-size: 0.9rem;
        line-height: 30px;
        font-weight: 600;
        color: $gray-300;
        margin: 0 0 0.5rem 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 400px;
        white-space: nowrap;
    }
}

.delete-labels-modal {
    .modal-content {
        padding: 20px 16px 24px 16px;
    }

    .modal-body {
        padding: 0;
    }

    .modal-header {
        flex-direction: column;
        align-items: center;

        .close {
            display: none;
        }
    }

    .modal-title {
        font-size: 18px;
        line-height: 1;
        margin-top: 12px;
        font-weight: 700;
        color: #071420;
        letter-spacing: 0.02em;
    }

    .message {
        margin-bottom: 20px;
        margin-top: 8px;
        color: #0B2235;
        font-size: 18px;
        text-align: center;
        line-height:30px;
    }
}