.reports {
  input.DateInput_input {
    padding-right: 0px;
    margin-right: 0px;
    padding-bottom: 0;
    padding-top: 1rem;
    font-size: 1rem;
  }

  div.SingleDatePicker{
    width:100%;
  }

  div.SingleDatePickerInput{
    width:90%;
  }

  div.DateInput{
    width:100%;
  }
}