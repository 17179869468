.RecordingModalHeader {
    font-Size: 90%;
}

.RecordingModalBody {
    font-Size: 120%;
}

.TransferNumberLabel {
    line-height: 3.0rem
}

.right{
    float: right;
    padding-left: 2%;
}