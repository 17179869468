.pbi-report-container {
  height: 98vh;
  width: 100%;
}

.pbi-report-container iframe {
  border: none;
  padding: 0;
  margin: 0;
}
