.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    .btn + .btn {
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-down(xs) {
    flex-direction: column;

    div {
      width: 100%;

      .btn + .btn {
        margin-left: 0;
        margin-top: 1rem;
      }

      .btn {
        display: block;
        width: 100%;
      }
    }

    div + div {
      margin-top: 1rem;
    }
  }
}
