.AssociationModal {
  .family-members {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 0.5rem;

    .family-member {
      display: flex;
      align-items: center;
      line-height: 2.5rem;
      border-bottom: 1px solid rgba(108, 117, 125, 0.25);

      input {
        margin-right: 10px;
      }

      label {
        display: block;
        width: 100%;
        margin-bottom: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
