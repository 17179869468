.AppWrapper .Main .content .EbfGeneratorEdit {
    .ebfgeneratorcard {
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            width: 50%;
        }
        .filter-row {
            width: 100%;
        }

        .filter-col {
            flex: 0 0 3.3%;
            font-weight: bold;
            text-transform: lowercase;
        }

        .filter-col-small {
            flex: 0;
            font-weight: bold;
            text-transform: lowercase;
        }

        .without-padding{
            padding: 0;
        }

        .without-padding-left {
            padding-left: 0;
        }

        .filter-row-children {
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
        }

        .extra-margin-bottom {
            margin-bottom: 2rem;
        }
    }
    .questions-card {
        max-width: 50%;
        min-width: 30rem;
        position: absolute;
        top: 0;
        right: 0;
      }
}