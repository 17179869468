.template-summary {
    .template-name {
        line-height: 1;
    }
    
    .template-description, .template-location {
        font-size: 14px;
        font-weight: 900;
        color: #989AA8;
        line-height: 22px;
    }

    pre {
        white-space: pre-wrap;
        word-break: break-all;
        font-family: sans-serif;
        font-size: 1rem;
    }
}
