.broadcast-history {
    &__download-button.btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        min-width: 140px;
        font-size: 16px;
        margin-left: 24px;
    }
}