$sidebarWidth: 250px;

/*  Remove Arrows/Spinners   */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body,
html {
  background-color: $layoutBgColor;
}

#root,
.VoiceFriend-app {
}

.AppWrapper {
  display: flex;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .SideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: $sidebarWidth;
    max-width: $sidebarWidth;
    padding: 25px 0;
    background-color: #F4F4F4;

    .logo {
      display: block;
      align-items: center;
      width: 200px;
      min-height: 60px;
      height: 60px;
      margin-bottom: 25px;
    }

    #broadcast-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0;
      padding-left: 16px;
      padding-right: 16px;
      background: $white; 
      margin-top: 11px;

      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
    
    .bottom,
    .close {
      display: none;
    }

    .build-info {
      position: relative;
      pointer-events: none;
      left: 10px;
      font-size: 10px;
      a {
        pointer-events: all;
        color: $vfFunctionalGray;
      }
      span {
        color: $vfFunctionalGray;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
      position: relative;

      &.open {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
        width: 100%;

        .close {
          display: block;
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: $vfWhite;
          font-size: 16px;
        }

        .logo {
          margin: 25px auto;

          img {
            margin-bottom: 0.5rem;
          }
        }

        .VerticalNav {
          .label {
            text-align: center;
            margin-bottom: 1rem;
          }
        }

        .bottom {
          display: block;
          text-align: center;
          margin-top: auto;

          .Footer {
            a {
              display: none;
            }
          }

          .pipe {
            color: rgba($vfWhite, 0.25);
          }
        }
      }
    }

    li {
      .active{
        color: $vfOrange
      }
    }
  }

  .Main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: clip;

    .TopBar {
      position: fixed;
      top: 0;
      left: $sidebarWidth;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 60px;
      min-height: 60px;
      padding-left: 25px;
      padding-right: 25px;
      background-color: white;
      border-bottom: $vfElementBorder;
      z-index: 100;

      @include media-breakpoint-down(sm) {
        position: relative;
        left: 0;
        margin-bottom: -60px;

        &:before {
          position: absolute;
          top: 0;
          left: 25px;
          content: ' ';
          width: 38px;
          height: 60px;
          background-image: url('../images/mobile-logo.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
    }

    .content {
      flex: 1 1 auto;
      margin-top: 5px;
      padding: 25px;

      @include header-bar;
    }

    .container {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-left:0px;
    }

    .Footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      min-height: 60px;
      padding-left: 25px;
      padding-right: 25px;
      border-top: $vfElementBorder;

      a {
        color: $vfBlue;
        font-weight: 700;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.SchedulingButtons {
  text-align: right;
}

.Templates, .broadcast-history {
  .itemCount {
    border-radius: 10px;
    font-weight: bold;
    color: $vfPink;
    padding-left: 0.6rem;
    display: flex;
    align-items: center;
    height: 40px;
    white-space: nowrap;

    [role='status'] {
        width: 24px;
        height: 24px;
    }
  }
}