.SurveyQuestionListIndex {
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-top: 60px;
  }

  @include media-breakpoint-up(md) {
    .index-filter {
      &__filter:first-of-type {
        width: 150px;
      }

      &__search {
        select[name="searchField"] {
          width: 150px;
        }

        .SearchInput {
          input {
            width: 255px;
          }
        }
      }
    }
  }
}
