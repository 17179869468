.create-template-modal {
    .modal-dialog {
        max-width: 981px;
    }
    .modal-content {
        padding: 24px 28px 58px 32px;
    }

    .modal-title {
        font-weight: 600;
        color: #1F0B40;
        display: flex;
        align-items: center;

        svg {
            margin-right: 12px;
        }
    }

    .modal-body {
        padding-top: 70px;

        .description {
            font-weight: 800;
            margin-bottom: 35px;
        }

        button {
            margin-right: 24px;
            width: calc(33.33% - 16px);
            aspect-ratio: 1/1;
            box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            background: $white;
            border: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            transform: scale(1);
            transition-duration: 0.3s;
            transition-property: transform;
            transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            
            &:hover {
                transform: scale(1.05);
            }

            &:last-of-type {
                margin-right: 0;
            }

            svg {
                margin-top: 25%;
                margin-bottom: 24px;
            }

            .button-title {
                color: $vfBlue;
                font-weight: 800;
                margin-bottom: 16px;
            }

            .button-description {
                font-weight: 600;
                padding: 0 20px;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}