.dateInputCalendar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.dateInputCalendarButton {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
  pointer-events: auto;
}
