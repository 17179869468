.nav-tabs.vfTabs {
    .nav-link {
        color: $gray-300;
        border: 0;
        border-bottom: 1px solid $gray-300;
        font-weight: 700;
        font-size: 18px;

        &.active {
            color: $vfBlue;
            border-bottom: 1px solid $vfBlue;
        }
    }
}