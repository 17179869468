.ProfilesIndex {
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-top: 60px;

    .action.export-data {
      display: none;
    }
  }

  .account-integration
  {
    text-align: left;
    display:flex;
    flex: 1;
    justify-content: center;

    li{
      list-style: none;
      color: $vfOrange;
      vertical-align: center;
    }
    div {
      padding: 0.45rem 0.6rem;
    }
  }

  .Profiles-Table {
    .Table__horizontal-scrolltrack {
      bottom: 68px;
    }
  }
}
