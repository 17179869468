$offColor: #E6E6E6;

.switch {
    position: relative;
    width: 48px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;

    &--disabled {
      .switch__label {
        opacity: .5;
        cursor: not-allowed;
      }

      .switch__circle {
        opacity: 0.8;
        cursor: not-allowed;
      }

      &__checkbox:checked + &__label {
        .switch__bg-shadow {
          opacity: 0;
        }
      }
    }

    &__checkbox {
      display: none;
    }

    &__label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border-radius: 1rem;
      margin: 0;
      position: relative;
      background: #E6E6E6;

      &--inner {
        display: block;
        width: 200%;
        margin-left: -200%;
        transition: all 0.2s ease 0s;
        height:28px;
        &:before,
        &:after {
          position: relative;
          display: block;
          float: left;
          width: 50%;
          height: 28px;
          padding: 0;
          line-height: 28px;
          font-size: 1rem;
          color: #fff;
          font-weight: bold;
          box-sizing: border-box;
        }
        &:before {
          content: attr(data-yes);
          text-transform: uppercase;
          padding-left: 10px;
          background-color: inherit;
          color: #fff;
          z-index: 1;
        }

        &:after {
            content: attr(data-no);
            text-transform: uppercase;
            padding-right: 10px;
            background-color: $offColor;
            color: #fff;
            text-align: right;
        }
      }
    }

    &__circle {
      display: block;
      width: 22px;
      margin: 3px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      border-radius: 50%;
      transition: all 0.2s ease 0s;
      box-shadow: 0px 3px 6px rgba($black, 0.16);
      z-index: 2;
      height:22px;
    }

    &__bg-shadow {
      height: 28px;
      width: 48px;
      position: absolute;
      border-radius: 1rem;
      box-shadow: inset 0px 3px 6px rgba($black, 0.16);
    }
    
    &__checkbox:checked + &__label {
      .switch__label--inner {
        margin-left: 0;
      }
      
      .switch__circle {
        right: 0px;
        box-shadow: none;
      }
    }
  }