.dateCloseButton {
  //padding-left: 32rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  display:flex;
  justify-content: right;
}

.result-pane{
  border-width: 0px 1px;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  background-color: #d3d3d3;
  padding: 5px 5px 5px 5px;
  // margin-top: 20px;
}

.discharged-patients{
  button.btn-link:focus{
    box-shadow:none;
  }
  .status-and-tags{
    border-top: 1px dotted grey;
    margin-top: .5rem;
  }
  button h3{
    padding-top:.5rem;
  }
  .contact-row div.col div.col,.contact-row div.col form{
    padding-left:0;
    padding-right:0;
  }
  div.section-header{ 
    border-top: 1px grey dotted; 
    margin-top: .33rem;
  }
  div.section-header *{ 
    padding-top:0;
  }
  
  h6{
    text-decoration: none;
    font-weight: normal;
  }
  .patientName {
    line-height: 4.0rem;
    font-size: 1.75rem;
    font-weight: 900;
  }

  .updateStatusSelect {
    padding-top: 0.5rem;
    z-index: 100;
  }

  .updateStatusButton {
    padding-top: 0.7rem;
  }

  .surveyAnswerCell {
    float: right; 
  }

  .surveyQuestions {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  
  .sortDirection {
    border-bottom-left-radius:0%;
    border-top-left-radius:0%;
    margin-left:0px;
  }

  .sortDirection .btn {
    padding: 0px;
  }

  div.DateRangePickerInput__withBorder{
    border: 1px transparent;
  }

  #input-group-dropdown-1, #input-group-dropdown-2 {
    border-width: 1px;

    &:hover, &:focus, &:active {
      box-shadow: none;
    }
  }

  #input-group-dropdown-1 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  #input-group-dropdown-2 {
    border-radius: 0;
  }

  #sort-direction-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: 100px;
  }

  $control-radius: $border-radius;
  /**
  * <div class="react-tags">
  *   <div class="react-tags__selected">
  *     <button class="react-tags__selected-tag">
  *       <span class="react-tags__selected-tag-name" />
  *     </button>
  *   </div>
  *   <div class="react-tags__search">
  *     <div class="react-tags__search-wrapper">
  *       <input class="react-tags__search-input" />
  *       <div />
  *     </div>
  *     <div class="react-tags__suggestions">
  *       <ul>
  *         <li class="is-active">
  *           <mark />
  *         </li>
  *         <li class="is-disabled">
  *           <mark />
  *         </li>
  *       </ul>
  *     </div>
  *   </div>
  */
  .react-tags {
    background-color: white;
    position: relative;
    //padding: 8px;
    padding-top:4px;
    padding-bottom:4px;
    border: 1px solid #6C757D;
    border-radius: $control-radius;
    margin-bottom:1rem;
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
    display:flex;
  }

  .react-tags.is-focused {
    
  }

  .react-tags__selected {
    margin-top:.12rem;
  }

  .react-tags__selected-tag {
    box-sizing: border-box;
    margin: auto;
    margin: .25rem;
    padding: 3px 8px;
    border: 1px solid #D1D1D1;
    border-radius: $control-radius;
    background: #F1F1F1;


    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
  }

  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    //border-color: #B1B1B1;
  }

  .react-tags__search {
    display: flex;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    /* match tag layout */
    padding: .66rem;
    
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    
  }

  .react-tags__search-wrapper{
    margin-top:auto;
    margin-bottom:auto;
  }

  @media screen and (min-width: 30em) {

    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }

  }

  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search-input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  @media screen and (min-width: 30em) {

    .react-tags__suggestions {
      width: 240px;
    }

  }

  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: $control-radius;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }

  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
    padding: 0;
  }

  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }

  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }

  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  .form-control.date{
    border-bottom-left-radius:0px;
    border-top-left-radius:0px;
  }
  .react-datepicker-wrapper{
    display:flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
  }
  .react-datepicker-wrapper div{
    display:flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
  }
  .form-group.datepicker{
    border: 1px solid #6C757D;
    background: white;
    padding-bottom: 3px;
    padding-right: 8px;
    height: 50px;
    left: -1px;
    margin-right: -2px;
    width: 260px;
  }

  .filter-interface {
    .btn {
      height: 50px;
    }
  }
  
  div.DateInput{
    width:110px;
  }

  .filter-interface .DateInput_input{
    padding-right:0px;
    margin-right:0px;
    padding-left:0rem;
    margin-left:.5rem;
  }
  .filter-interface{
    .SearchInput Input, .SelectInput select {
      border-radius: 0px;
    }
  }
  .discharged-patient-list-item{
    margin-bottom:.25rem;
  }

  .DateRangePicker_picker {
    z-index: 999 !important;
  }

  .control-container {
    min-width: 160px;

    .SelectInput, .SearchInput {
      max-width: 100%;
      width: 260px;

      .form-control {
        height: 50px;
        border-width: 1px;
        border-color: $vfBlue;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
      }
    }
  }

  .calltoreviewreason {

    select {
      padding-top: 0.25rem;
      padding-right: 1.75rem;
      padding-bottom: 0.25rem;
      padding-left: 0.25rem;
    }
  }
}
