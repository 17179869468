.MobileNav {
  background: $vfBlue;
  color: $vfWhite;
  height: 65px;
  padding: 10px 0;
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  &--List {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &--ListItem {
    flex: 1;
    text-align: center;

    a,
    a:visited,
    a:active,
    span {
      color: $vfWhite;
    }
  }

  svg {
    display: block;
    margin: 0 auto 0.25rem;
  }
}
