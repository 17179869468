.sms-inbox {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 50px);

    &__separator {
        width: 10px;
        flex-shrink: 0;
    }

    &__conversation-view {
        border: 1px solid $gray-200;
        display: flex;
        flex-direction: column;
        height: 100%;


        &__header {
            border-bottom: 1px solid $gray-200;
            padding: 14px 16px 14px 10px;
            
            & > div {
                overflow: hidden;
                display: flex;
                flex-shrink: 0;

                &:not(:last-of-type) {
                    margin-bottom: 14px;
                }
            }
        }

        &__refresh-button {
            border: 0;
            margin-left: auto;
            padding: 0;
            background: transparent;
            padding-right: 3px;
            padding-left: 3px;
            color: $vfFunctionalGray;
            margin-left: 13px;

            &:not(:disabled):hover {
                color: darken($vfFunctionalGray, 15);
            }

            &:focus {
                outline: none;
            }

            &.refreshing {
                svg {
                    animation: spinner-border 1s linear infinite;
                }
            }
        }

        &__searchbox {
            margin-bottom: 0;
            flex-grow: 1;

            input {
                height: 33px;
                padding-left: 32px;
            }

            .Input__icon {
                width: 13px;
                height: 13px;
                position: absolute;
                top: 10px;
                left: 12px;
                path {
                    fill: $gray-300;
                }
            }
        }

        &__orderby-button {
            color: $vfBlue;
            display: flex;
            align-items: center;
            border: 1px solid $blue-100;
            border-radius: 4px;
            background: $white;
            width: 145px;
            flex-shrink: 0;
            margin-right: 16px;
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: ease;
           
            &:hover {
                border-color: $vfBlue;
            }

            &__sorts {
                margin-right: 12px;
                display: flex;
                flex-direction: column;
                margin-left: 16px;
            }

            &__sort {
                width: 13px;
                height: 9px;
                path {
                    fill: $gray-300;
                }
                
                &:first-of-type {
                    margin-bottom: 2px;
                }

                &.active path {
                    fill: $vfBlue;
                }
            }
        }

        &__read-filter {
            .btn:hover:not(.active) {
                background: #ECF2FF;
                color: #868E96; 
            }
        }

        &__conversations {
            overflow-y: auto;
            height: 100%;

            &::-webkit-scrollbar-thumb {
                background-color: $gray-200;
            }

            &__conversation {
                cursor: pointer;
                // height: 140px;
                padding: 14px 40px 18px 18px;
                border-bottom: 1px solid $gray-200;
                letter-spacing: 0.2px;
                font-size: 16px;
                line-height: 20px;
                color: $dark-600;
                position: relative;
                

                &.selected {
                    background: #ECF2FF;
                }

                &__title {
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 18px;
                    line-height: 20px;
                    margin-bottom: 12px;

                    svg {
                        margin-right: 7px;
                    }
                    
                }

                &__datetime {
                    color: $vfBlue;
                    margin-bottom: 15px;
                    font-weight: 500;
                }

                &__preview {
                    margin-bottom: 0;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;

                    &.preview--hidden {
                        font-style: italic;
                        color: $gray-300;
                    }
                }

                &__dots {
                    position: absolute;
                    right: 8px;
                    top: 40px;
                }
                &__dots-btn {
                    cursor: pointer;
                    
                    border-radius: 50%;
                    border: 0;
                    background: transparent;
                    width: 32px;
                    height: 32px;
                    

                    &:focus {
                        outline: none;
                    }
                    &:hover, &:focus {
                        background: $vfFunctionalGray;

                        svg {
                            color: $white;
                        }
                    }
                }

                &.read {
                    .sms-inbox__conversation-view__conversations__conversation__title,
                    .sms-inbox__conversation-view__conversations__conversation__datetime, 
                    .sms-inbox__conversation-view__conversations__conversation__preview {
                        color: $input-placeholder-color;
                    }
                }
            }
            
        }

        
    }

    &__chat-view {
        border: 1px solid $gray-200;
        width: 100%;
        min-width: 0;

        &__placeholder {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &__logo {
                opacity: .5;
                max-width: 40%;
                width: 428px;
            }

            &__text {
                margin-top: 46px;
                margin-bottom: -70px;
                text-align: center;
            }
        }

        &__chat {
            padding-left: 24px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            height: 100%;

            header {
                padding: 14px 14px 7px;
                line-height: 38px;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 1px;
                border-bottom: 1px solid #DFE0EB;
                flex-shrink: 0;
                display: flex;
                justify-content: space-between;

                
                .recipient {
                    margin-right: 8px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }

                .phone-number {
                    color: $gray-300;
                    white-space: nowrap;
                }
            }

            &__messages {
                flex-grow: 1;
                margin-bottom: 8px;
                min-height: 200px;
                height: 100%;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                margin-right: 3px;
                padding-right: 23px;
                overflow-wrap: break-word;


                &::-webkit-scrollbar-thumb {
                    background-color: $gray-200;
                }

                &__date {
                    background: $gray-100;
                    border-radius: 10px;
                    min-width: 156px;
                    padding: 6px 20px;
                    height: 40px;
                    margin: 0 auto;
                    text-align: center;
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 1px;
                    font-weight: 500;
                    margin-top: 40px;
                    flex-shrink: 0;

                    & + .sms-inbox__chat-view__chat__messages__message {
                        margin-top: 26px;
                    }
                }

                &__message {
                    margin-top: 32px;
                    max-width: 70%;
                    border-radius: 10px;
                    flex-shrink: 0;
                    border: 1px solid $gray-200;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    padding: 14px 20px 0px 20px;
                    align-self: flex-start;
                    overflow-wrap: break-word;
                    position: relative;
                    white-space: pre-wrap;

                    &:not(.message--broadcast):not(.message--direct) {
                        margin-left: 34px;
                    }

                    &.message--direct {
                        background: $vfBlue;
                        color: $white;
                        align-self: flex-end;
                        border-color: $vfBlue;

                        .sms-inbox__chat-view__chat__messages__message__time {
                            margin-right: -5px;
                            text-align: right;
                        }                        
                    }

                    &.message--broadcast {
                        background: $vfPink;
                        border-color: $vfPink;
                        color: $white;
                        align-self: flex-end;
                        padding-right: 40px;

                        .sms-inbox__chat-view__chat__messages__message__time {
                            margin-right: -25px;
                            text-align: right;
                        }
                    }

                    &.message--hidden {
                        font-style: italic;
                        background: $gray-100;
                        color: $gray-300;
                        border-color: $gray-100;
                    }

                    &__time {
                        font-style: normal;
                        display: block;
                        text-align: left;
                        position: relative;
                        bottom: -25px;
                        margin-left: -5px;
                        color: $vfBlack;
                        height: 14px;
                        line-height: 14px;
                    }

                    &__heading {
                        margin-bottom: 12px;
                        color: inherit;
                        line-height: inherit;
                    }

                    &__error-actions {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 18px;
                        line-height: 1;

                        svg:nth-child(2) {
                            margin-top: 6px;
                            cursor: pointer;
                        }

                        .message--reply & {
                            right: -30px;
                        } 
                        
                        .message--direct &, .message--broadcast & {
                            left: -30px;
                        }
                    }

                    &__broadcast-icon {
                        position: absolute;
                        right: 13px;
                        top: 10px;
                    }

                    &__hide-icon {
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        left: -34px;
                        top: 50%;
                        transform: translateY(-50%);
                        fill: #868E96;
                  
                        &:hover, &:active {
                          fill: $input-color;
                        }
                    }

                }
            }

            &__textbox-container {
                margin-right: 26px;
            }

            &__textbox {
                margin-bottom: 0;

                textarea {
                    resize: none;
                    padding-bottom: 72px;
                }
            }

            &__textbox-contentlength {
                float: right;
                padding: 0.3rem;
            }

            &__textbox-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute; 
                bottom: 12px;
                right: 12px;

                .emoji-picker__button {
                    &:not(.active):not(:disabled):hover svg {
                        fill: darken($gray-300, 15);
                    }
                }
            }

            &__textbox-warning {
                color: $orange;
                margin-bottom: -4px;
                margin-top: 14px;
                font-size: 14px;
                line-height: 1rem;

                svg {
                    margin-right: 4px;
                    
                }
            }

            &__send {
                margin-left: 16px;
            }
        }
    }
    
}

.inboxUnreadMessageCount {
    display: inline-block;
    height: 26px;
    background: $vfPurple;
    width: 33px;
    color: $white;
    border-radius: 100px;
    text-align: center;
    line-height: 26px;
    font-size: 0.875rem;

    position: relative;
    bottom: 2px;
    margin-left: 8px;
    
    &.beforeText {
        margin-left: -40px;
        margin-right: 8px;
    }
}