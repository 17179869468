.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); // semi-transparent white
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // make sure it's on top of other elements

  .loading-text {
    font-size: 1.5em; // Larger text size
    margin-bottom: 1rem; // A bit of space between text and spinner
    color: white;
  }
}
