$picker-width:200px;
.event-template-preview, .event-scheduler, .BroadcastScheduleModal {
    input.DateInput_input{
        padding-top:16px;
        margin-right:.5rem;
        margin-bottom:0;
        padding-left:0px;
        margin-left:12px;
        width:100%;
        font-size:16px;
    }

    div.DateInput, .DateInput,.SingleDatePicker,.SingleDatePickerInput  {
        width:100%;
        background-color:transparent;
    }
    .DateInput.DateInput_1{
        min-width:160px;
    }

    div.SingleDatePickerInput {
        background-color:transparent;
        padding-right:20px;
    }
    .rc-time-picker{
        margin-left: .5rem;
        font-size: $input-font-size;
    }
    
    input.rc-time-picker-input{
        border-radius: $border-radius;
        border-color: $input-border-color;
        padding: 1.4rem;
        font-size: $input-font-size;
        width:$picker-width;
        color:$vfGray;
        background-color: #F5F6F8;
    }
    .rc-time-picker-panel-inner input.rc-time-picker-panel-input {
        font-size:$input-font-size;
        border-radius: $border-radius;
        border-color: $input-border-color;
        padding: 1.1rem;
        padding-left:1.7rem;
        width:$picker-width;
        color:$vfGray;
    }
    
    .rc-time-picker-panel {
        font-size: $input-font-size;
    }
    
    .rc-time-picker-panel-inner {
        border-radius:$border-radius;
    }
    
    div.rc-time-picker-panel-input-wrap {
        padding:0;
        border-radius:$border-radius;
    }
    
    
    div.col>strong{
        margin-top:1rem;
        margin-bottom:1rem;
        display:flex;
    }
    
    #schedule .col button.btn-primary, #schedule .col button.btn-secondary{
        margin-top:.5rem;
    }
    
    .MuiFormGroup-root{
        padding-top:.5rem;
    }

    .wrapword {
        white-space: pre-wrap;
        word-break: break-all;
    }
    
}

