@-webkit-keyframes blink {
    to {
        visibility: hidden
    }
}

@keyframes blink {
    to {
        visibility: hidden
    }
}

.generate-content-modal {
    &__warning {
        color: $orange;
    }

    &__list {
        padding: 0;
        list-style: none;
        margin-top: 10px;

        &__email-item {
            &:hover {
                .generate-content-modal__list__item {
                    border-color: $vfBlue;
                }
            }
        }
        
        &__item {
            white-space: pre-wrap;
            padding: 10px;
            border: 1px solid $gray-200;
            cursor: pointer;
            margin-bottom: 16px;
            border-radius: 5px;
            
            &:hover {
                border-color: $vfBlue;
            }

            &.active {
                padding: 9px;
                border: 2px solid $vfBlue;
            }

            &.generating::after {
                animation: blink 1s steps(5,start) infinite;
                content: "▌";// ▋▌▍▎
                margin-left: 0.25rem;
                vertical-align: baseline;
            }
        }
    }

    &__error {
        color: $vfNegative;
        padding: 10px;
        margin-bottom: 10px;
    }
}