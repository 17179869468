.index-filter {
  width: 100%;

  form {
    display: flex;
    flex-wrap: wrap;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    flex-shrink: 0;

    @include media-breakpoint-down(sm) {
      display: none;
      position: fixed;
      top: 40px;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 9999;
      background: $vfWhite;
      padding: 1.5rem;

      &.open {
        display: block;
      }
    }
  }

  &__mobile-toggle {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    background: $vfWhite;
    line-height: 40px;
    padding: 0 1.5rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: $vfBlue;
    display: none;

    svg {
      margin-left: auto;
      margin-top: 0.8rem;
      color: $vfBlue;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  &__item-count {
    display: flex;
    align-items: flex-end;

    .itemCount {
        border-radius: 10px;
        font-weight: bold;
        color: $vfPink;
        padding-left: 0.6rem;
        display: flex;
        align-items: center;
        height: 45px;
        white-space: nowrap;
    }

    @include media-breakpoint-down(md) {
      .itemCount {
        padding-left: 0;
      }
    }
  }
}