.borderBox {
    width: 6rem;
    height: 6rem;
    margin: 0.5rem;
    background-color: #eeeeee;
    display: inline-block;
}

.divBorderLeft {
    border-top: 2px double #eeeeee;
    border-bottom: 2px double #eeeeee;
    border-left: 2px double #eeeeee;
}
.divBorderRight {
    border-top: 2px double #eeeeee;
    border-bottom: 2px double #eeeeee;
    border-right: 2px double #eeeeee;
}