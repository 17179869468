.AppWrapper .Main .content .Templates {
  &__title, &__description {
    margin-bottom: 28px;
  }

  .header-bar {
      align-items: flex-end;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 8px;
      margin-bottom: 16px;
  }

  .index-filter {
      margin-right: auto;
      max-width: 100%;
      width: auto;
  }

  .bulkActionsRow {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-top: 8px;
      position: relative;
      z-index: 1;
  } 

  .Template-Table {
    td:not(.actions) {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    .labels {
      display: flex;
      align-items: center;

      .overflow {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 28px;

        // workaround to prevent Safari from showing tooltip when text overflow is hidden with ellipsis
        &::after {
          content: '';
          display: block;
        }

        .entitylabel:not(:last-of-type) {
          margin-right: 4px;
        }

        .entitylabel.overflown + .entitylabel.overflown {
          pointer-events: none;
        }

        .entitylabel:not(.overflown) + .entitylabel.overflown {
          pointer-events: none;
        }

        &.overflown {
          .entitylabel {
            max-width: calc(100% - 15px);
          }
        }
      }

      .more-btn {
        width: 30px;
        height: 30px;
        border: 3px solid $purple-50;
        background: $vfPurple;
        border-radius: 5px;
        color: $white;
        font-size:14px;
        text-align: center;
        vertical-align: middle;
        flex-shrink: 0;
        margin-left: 5px;

        &:focus {
          outline: none;
        }
      }
      
    }
  }
}

.more-btn-popover {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
  padding-top: 10px;
  padding-bottom: 10px;

  .entitylabel {
    max-width: calc(50% - 4px);
  }
}