.GroupManager {
  margin-bottom: 1rem;

  .groups {
    span + span {
      margin-left: 5px;

      &:before {
        content: '+';
        margin-right: 5px;
        color: rgba(45, 41, 38, 0.25);
      }
    }
  }  
}

.GroupManagerModal {
  .modal-dialog {
    max-width: 400px;
  }

  h4 {
    text-align: center;
  }

  .groups {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 0.5rem;

    .group {
      display: flex;
      align-items: center;
      line-height: 2.5rem;
      border-bottom: 1px solid rgba(108, 117, 125, 0.25);

      input {
        margin-right: 10px;
      }

      label {
        display: block;
        width: 100%;
        margin-bottom: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.list-group-item {
  .removeIcon {
    line-height: 2.3rem;
  }
}

.list-group-item-nowrap{
  white-space: nowrap;
}