.account-integration
{
  text-align: left;
  display:flex;

  li{
    list-style: none;
    color: $vfOrange;
    vertical-align: center;
  }
}

.banner
{
  width:70%;
}
.card {
  .errors {
    color: #FF7052;
  }
}

.profiles{
  input.DateInput_input {
    padding-right: 0px;
    margin-right: 0px;
    padding-bottom: 0;
    padding-top: 1rem;
    font-size: 1rem;
  }

  div.SingleDatePicker{
    width:100%;
  }

  div.SingleDatePickerInput{
    width:90%;
  }

  div.DateInput{
    width:100%;
  }
  .compliance{
    .col{
      padding-top:.5rem;
      padding-bottom:.5rem;
    }
  }
}