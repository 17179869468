hr {
  border-top: 1px solid rgba(108, 117, 125, 0.25);
  width: 100%;
}

a {
  color: $vfLink;

  &:visited {
    color: $vfLink;
  }

  &:hover {
    color: $vfLinkActive;
  }

  &.btn {
    color: white;
  }

  &.link--secondary {
    color: $vfPink;

    &:visited {
      color: $vfPink;
    }

    &:hover {
      color: $pink-700;
    }
  }

  &.link--third {
    color: $vfRed;

    &:visited {
      color: $vfRed;
    }

    &:hover {
      color: $red-700;
    }
  }
}

.light-text {
  a {
    color: white;
  }
}

fieldset {
  legend {
    font-size: 1rem;
    font-weight: bold;
    color: #2D2926;
  }
}


