.account-switcher {
  width: 100%;
  padding: 25px;
  position: relative;

  .account-switcher__select {
    font-weight: 400;
    font-size: 0.8rem;
    width: 100%;

    &__control {
      color: $text-primary;
      border-radius: 8px;
      cursor: pointer;
      
      border-width: 2px;
      
      &--is-focused {
        border-color: $vfBlue !important;
        // box-shadow: $vfBlue 0px 0px 0px 1px;
        box-shadow: none;
      }
    }

    &__option,
    &__input,
    &__single-value,
    &__indicator {
      color: $text-primary;
      font-weight: 400;

      &--is-selected {
        background: $vfBlue;
        color: white;
      }
    }

    &__indicator {
      &:hover {
        color: $text-primary;
      }

      padding-left: 2px;
    }

    &__single-value {
      max-width: calc(100% - 10px);
    }

    &__option, &__single-value {
      cursor: pointer;

      .option-label {
        display: flex;
        align-items: flex-end;

        &.hasNotification {
          font-weight: bold;

          &:after {
            content: '';
            width: 6px;
            height: 6px;
            position: relative;
            bottom: 14px;
            background: $vfPurple;
            border-radius: 50%;
            margin-left: 3px;
            display: inline-block;
            flex-shrink: 0;
          }
        }

        &-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }
      
    } 

    &__menu {
      box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
    }

    &__menu-list {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &__total-count {
    position: absolute;
    right: 3px;
    top: 11px;
    background-color: $vfPurple;
    width: 33px;
    height: 26px;
    border-radius: 100px;
    z-index: 1;
    text-align: center;
    line-height: 26px;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .account-switcher__single{
    text-align: center;
    color: $vfLinkNav;
  }

  &__loading {
    color: $vfPink;
  }
}

