.form-group {
  position: relative;

  label {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #9597A6;
    margin-bottom: 8px;
    display: block;
  }

  .required-asterisk {
    position: absolute;
    font-weight: 900;
    right: 12px;
    color: #F0142F;
    z-index: 1;
    pointer-events: none;
  }

  .errors {
    color: #E65353;
    margin-top: 8px;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .maxlength-info {
    transform: translateY(-100%);
    position: absolute;
    right: 0;
    padding: 0.3rem;
  }

  .form-control {
    transition-property: border-color;
    transition-duration: .2s;
    transition-timing-function: ease;

    &[readonly] {
      background-color: $input-bg;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      border-color: rgba($vfBlack, .26);
      background-color: rgba($vfBlack, .26);
    }

    &.invalid {
      border-width: 2px;
      border-color: #E65353;
    }

    &[contenteditable] {
      overflow: auto;

      &[contenteditable='false'] {
        border-color: rgba($vfBlack, .26);
        background-color: rgba($vfBlack, .26);
      }

      &.isBlank::before {
        color: $input-placeholder-color;
        content: attr(data-placeholder);
        pointer-events: none;
        position: absolute;
      }

      &.oneLine {
        line-height: 17px;
        white-space: pre;
      
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { 
          display: none; /* Safari and Chrome */
        }
      }

      &:not(.oneLine) {
        white-space: pre-wrap;
        height: auto;
        resize: vertical;
      }
    }
  }

  .MuiInput-root {
    padding: 0;

    &:before, &:after {
        display: none;
    }

    &.Mui-focused {
        transition-property: border-color;
        transition-duration: .2s;
        transition-timing-function: ease;
        border: 2px solid $vfBlue;
    }

    .MuiInput-input {
        border: 0;
        padding: 12px 15px;
        &:focus {
            background: transparent;
        }
    }

    .select-placeholder {
      color: $input-placeholder-color;
    }

    .MuiSelect-icon {
        right: 9px;
    }
  }

  &.Textarea {
    .form-control {
      margin-top: 0;
    }
  }

  &.SearchInput {
    width: 420px;
    max-width: 100%;

    .form-control {
      padding-left: 45px;

      &:focus + .Input__icon {
        g {
          fill: $input-color;
        }
      }
    }

    .Input__icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
      top: calc(50% - 10px);

      g {
        fill: #868E96;
      }
    }
  }

  &.PasswordInput {
    .Input__icon {
      cursor: pointer;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      fill: #868E96;
      margin-right: 5px;

      &:hover, &:active {
        fill: $input-color;
      }
    }
  }

  &.SelectInput {
    .form-control {
      line-height: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #868E96;
      transition-property: border-color, background;
      transition-duration: .2s, .2s;

      &:focus {
        color: #202124;
        background: $white;
        transition: none;

        .SelectInput__arrow {
          color: #A6ADB4;
        }
      }

      .SelectInput__arrow {
        position: absolute;
        right: 1rem;
      }
    }

    .dropdown-toggle:after {
      content: none;
    }

    .dropdown {
      .dropdown-menu {
        padding: 0;
        width: 100%;
        border-radius: 6px;
        background: $white;
        border: 2px solid $white;
        overflow-y: auto;
        max-height: 50vh;

        .dropdown-item {
          height: 45px;
          color: #202124;
          padding-right: 14px;
          padding-left: 14px;
          display: flex;
          align-items: center;

          &:hover, &:active {
            background: #F6F8F9;
            color: $vfBlue;
            font-weight: 500;
          }
        }
      }

      &.show {
        .form-control .SelectInput__arrow {
          transform: rotate(180deg);
          transition: transform .2s ease;
        }

        .dropdown-menu {
          border-color: $vfBlue;

          &[x-placement|=top] {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom: none;
    
            & + .form-control {
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }

            .dropdown-item {
              border-bottom: 2px solid #F6F8F9;
            }
          }
    
          &[x-placement|=bottom] {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top: none;
    
            & + .form-control {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }

            .dropdown-item {
              border-top: 2px solid #F6F8F9;
            }
          }
        }
      }
    }
  }
}

.form-check {
  padding-left: 26px;

  &-label {
    color: #202124;
  }

  &-input {
    margin-left: -26px;
    margin-top: 0;
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
    appearance: none;
    border: 1px solid $vfFunctionalGray;
    border-radius: 3px;
    padding: 2px;

    &:disabled {
      opacity: .5;

      & + .form-check-label {
        color: #202124;
        opacity: .5;
      }
    }

    &:after {
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
      border: 1px solid $white;
      border-radius: inherit;
      content: "";
    }

    & + .form-check-icon {
      opacity: 0;
      position: absolute;
      top: calc(50% - 5px);
      margin-left: -22px;
      fill: $white;
      pointer-events: none;
    }

    &:checked {
      &:after {
        background-color: $vfBlue;
      }
      & + .form-check-icon {
        opacity: 1;
      }
    }
  }
}

.Date-Input {
  width: 150px;
  
  .form-control:focus + .Input__icon {
    fill: $input-color;
  }

  .Input__icon {
    fill: #9597A6;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
  }
}

.Date-Range-Input {
  width: 335px;

  &__icons-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1px;
    top: calc(50% - 22px);
    z-index: 1;
  }

  .form-control:focus + &__icons-container .Input__icon[class*="Date-Range-Input__calendar"] {
    fill: $input-color;
  }

  .Input__icon[class*="Date-Range-Input__calendar"] {
    width: 20px;
    height: 20px;
    fill: #9597A6;
  }

  .Input__icon[class*="Date-Range-Input__arrow"] {
    fill: #9597A6;
    width: 44px;
    height: 44px;
    transition: fill 0.2s ease;
    padding: 14px;

    &.disabled {
      pointer-events: none;
    }

    &.Date-Range-Input__arrow-left {
      transform: rotate(90deg);
    }

    &.Date-Range-Input__arrow-right {
      transform: rotate(-90deg);
    }

    &:not(.disabled):hover, &:not(.disabled):active {
      fill: $input-color;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: $custom-select-border-radius;
  background-image: url('../images/select-arrow.svg');
  background-size: 17px 9px;
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.input-group {
  .form-group {
    flex: 1 1 auto;

    @include media-breakpoint-down(xs) {
      flex-basis: 100% !important;

      .input-group-prepend {
        margin-right: 0;
      }
    }
  }
}

.DayPicker {
  position: absolute;
  z-index: 10;
}

.selected-option {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 18px;
}


.menu-option {
  padding-right: 10px;
  display: block;
}