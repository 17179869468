.unsubscribeEmail {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 276px;

  .title{
    font-size: 24px;
    line-height: 28.13px;
    margin-bottom: 24px;
  }

  .content{
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    margin-bottom: 24px;
  }

  .description{
    font-size: 12px;
    line-height: 14.06px;
    margin-bottom: 24px;
  }

  .unsubscribeButton{
    width: 201px;
    height: 40px;
    border-radius: 12px;
    margin-bottom: 8px;
  }

  .resubscribeButton{
    width: 214px;
    height: 40px;
    border-radius: 12px;
    margin-bottom: 8px;
  }

  .form{
    align-items: center;
  }

  .errors {
    color: #FF7052;
  }
}