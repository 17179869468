.DiscardChangeModal {
  .modal-body {
    text-align: center;

    svg,
    .message {
      margin-bottom: 2rem;
    }
  }

  @include media-breakpoint-down(xs) {
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
