@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
$font-family-sans-serif: 'Roboto', sans-serif;

// Primary Palette
$white: #ffffff;
$black: #000000;
$blue: #0F4CDB;
$orange: #F88225;
$purple: #8F4CE6;
$pink: #DF459C;
$green: #00A67E;

$blue-700: #0C3DAF;
$blue-500: #E2E8FF;
$purple-700: #6022C1;
$purple-50: #F0E9FA;
$pink-700: #B2377D;
$gray-100: #E0E0E0;
$gray-200: #CECECE;
$gray-300: #8D8D8D;
$blue-100: #D2DDF9;
$dark-600: #101010;
$red-700: #e42728;

// Aliases
$vfOrange: $orange;
$vfBlue: $blue;
$vfGray: #343233;
$vfYellow: #f1c40f;
$vfRed: #EA5455;
$vfPurple: $purple;
$vfPink: $pink;
$vfBlack: $black;
$vfWhite: $white;
$vfGradientBlueStart: $vfBlue;
$vfGradientBlueEnd: #00313A;
$vfElectronBlue:#0984e3;
$vfJigglyPuff:#ff9ff3;

// Functional Palette
$vfNegative: #FF7052;
$vfWarning: #FFD754;
$vfInformation: #2D9CCC;
$vfPositive: #15B375;
$vfFunctionalGray: #6C757D;

$vfLink: $vfBlue;
$vfLinkActive: $blue-700;
$vfLinkActiveNav: $vfBlue;
$vfLinkNav: #1C1C1C;
$link-decoration: underline;

$vfElementBorder: 1px solid rgba(108, 117, 125, 0.25);

$primary: $pink;
$secondary: $blue;
$light: $white;

$layoutBgColor: $white;

$border-radius: 10px;
$btn-border-radius: 25px;
$btn-square-border-radius: 6px;
$btn-border-radius-lg: 30px;
$btn-border-radius-sm: 20px;
$btn-link-color: #868E96;
$btn-link-hover-color: $vfBlue;
$btn-link-disabled-color: #868E96; 

$input-border-color: #F5F6F8;
$input-bg: #F5F6F8;
$input-border-width: 2px;
$input-border-radius: 6px;
$input-font-size: 1rem;
$input-padding-y: 0.75rem; // 12px
$input-padding-x: 0.9375rem; // 15px
$input-height: 45px;
$input-color: #2D2D2D;
$input-placeholder-color: #868E96;
$input-focus-border-color: $vfBlue;

$custom-select-border-radius: $btn-border-radius-sm;

$navbar-nav-link-padding-x: 1rem;

$navbar-light-color: $primary;
$navbar-light-hover-color: darken($primary, 10);

$text-primary: #1D2433
