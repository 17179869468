.TopBar {
  & > div {
    border-right: 1px solid $vfFunctionalGray;
    padding-left: 20px;
    padding-right: 20px;

    span, a {
      color: $vfBlue;
      font-weight: 700;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}
