
  .account-list {
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 25px;
    transform: translateY(-50%);
    right: 20px;
    color: red;
  }

  .saved-caption {
    margin-top: 50px;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 1rem;
    color: #9597a6;
  }

  .empty-domain {
    font-weight: bold;
    font-size: 0.75em;
    line-height: 1rem;
    color: #000;
  }

  .domain-name {
    font-size: 1em;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 380px;
    margin: 0 0.25rem;
  }

  .broadcast-container {
    height: 2px;
    margin: 30px;
    color: 'black';
  }

  .broadcast-cell {
    width: 250px;
    height: 110px;
    border-radius: 2px;
    border: 1px solid #000;
    padding: 5px;    
    img {
      width: 240px;
      height: 100px;
      border-radius: 2px;
      overflow: 'hidden';
      object-fit: contain;
    }
  }

  .broadcast-button-upload {
    background-color: #d8dee3;
    border: 0px;
    width: 120px;
    height: 35px;
    border-radius: 5px;
  }

  .broadcast-button-remove {
    margin-left: 10px;
    background-color: #facdcd;
    border: 0px;
    width: 120px;
    height: 35px;
    border-radius: 5px;
  }
  

