.rrule{
  .rrule-weekly-labels {
    .btn {
      min-width: 30px;
      height: 30px;
    }
  }
  select{    
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}
