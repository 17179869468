.linkVoicefriend {
  display: flex;
  height: 100vh;
  overflow-y: auto;

  .linkVoicefriend-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    flex-basis: 50%;
    padding: 0 15px;
    padding-top: 40px;
    padding-bottom: 30px;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 80vh;

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 380px;


      img.logo {
        margin-bottom: 30px;
        height: 10vh;
      }

      h1 {
        margin-bottom: 30px;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.5rem;
      }

      .support-text {
        font-size: 0.75rem;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
        color: #6C757D;
        margin-bottom: 3px;

        span {
          margin-bottom: 8px;
          display: inline-block;
        }

        a {
          font-size: 0.75rem;
          text-transform: none;
        }
      }
    }

    .copyright {
      padding-top: 30px;
      margin-top: auto;
    }
  }

  .link-voicefriend-splash {
    flex: 1;
    flex-basis: 50%;

    &__img {
      background-image: url('../images/linkVoicefriend-right-side.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 80vh;
      position: fixed;
      left: 38%;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  .link-caremerge-splash {
    flex: 1;
    flex-basis: 50%;

    &__img {
      background-image: url('../images/linkCaremerge-right-side.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 80vh;
      position: fixed;
      left: 38%;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
}

.androidBadge {
  width: 50%;
  height: 5.2rem;
  float: left;
}

.appleBadge {
  width: 50%;
  height: 5.2rem;
  float: right;
}

.appStoreBadges {
  display: 'inline-block';
  width: '100%';
}

@media screen and (min-width: 500px) {
  .appStoreBadges {
    display: none;
  }
}