.customCardMiddle {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    padding:.5rem;
    height:100%;
}
div.chart{
    padding-right:30px;
}
.summary thead tr{
    font-weight: bold;
    text-decoration: underline;
}
.summary td{
    padding:5px;
    text-align:center;
}
.summary td:first-child{
    text-align:left;
}