$calendarButtonRadius: 5px;

$defaultEventColor: $vfInformation;
$emergencyEventColor: $vfRed;
$onDemandEventColor: $vfPositive;
$staffEventColor: $vfYellow;
$seniorsEventColor: $vfBlack;
$familyEventColor: $vfJigglyPuff;
$complianceEventColor: $vfOrange;
$automaticEventColor: $vfOrange;

.datepicker-button {
  border: lightgray solid 1px;
  background-color: white;
  padding: 0.5rem;
  height: 48px;
  text-align: left;

  &:hover {
    border: gray solid 1px;
  }
}

.Calendar {
  background-color: white;  
  padding: 1.56rem;

  &__filter-icon {
    position: relative;

    .fa-plus {
      position: absolute;
      right: -2px;
      top: 8px;
      font-size: 10px;
      color: gold;
    }
  }

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    .title {
      margin-bottom: 0;
      padding: 10px;

      &:hover {
        border: 1px dotted black;
      }
    }

    .calendar-view {
      display: flex;
      justify-content: flex-end;
      flex: 1;

    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      .title {
        margin: 1rem 0;
      }
    }
  }

  .calendar-navigation {
    flex: 1;

    .button-group {
      margin-right: 10px;

      button {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .button-group {
    display: inline;

    button {
      border-radius: 0;
      border-right: 1px solid $vfLinkActive;
    }

    button:first-of-type {
      border-top-left-radius: $calendarButtonRadius;
      border-bottom-left-radius: $calendarButtonRadius;
    }

    button:last-of-type {
      border-top-right-radius: $calendarButtonRadius;
      border-bottom-right-radius: $calendarButtonRadius;
      border-right: none;
    }
  }

  .calendar-button {
    font-size: 1rem;
    background-color: $vfBlue;
    color: white;
    border: 0;
    padding: 0.625rem;
    border-radius: $calendarButtonRadius;

    &.active,
    &:hover,
    &:disabled {
      background-color: $vfLinkActive;
    }
  }

  .fc-time {
    color: white;
    font-weight: bold;
  }

  .fc-v-event .fc-event-main {
    color: inherit;
  }

  .fc-event {
    background-color: $defaultEventColor;
    border: 1px solid $defaultEventColor;
    word-break: break-all;
    overflow: hidden;
    color: white;

    &:hover {
      color: white;
    }

    .fc-event-title,
    .fc-event-time {
      color: black;
    }

    &.Compliance {
      background-color: $complianceEventColor;
      border: 1px solid $complianceEventColor;
    }

    &.Emergency{
      background-color: $emergencyEventColor;
      border: 1px solid $emergencyEventColor;
    }

    &.OnDemand {
      background-color: $onDemandEventColor;
      border: 1px solid $onDemandEventColor;
    }

    &.Seniors {
      background-color: $seniorsEventColor;
      border: 1px solid $seniorsEventColor;
    }

    &.Staff {
      background-color: $staffEventColor;
      border: 1px solid $staffEventColor;
    }

    &.Family {
      background-color: $familyEventColor;
      border: 1px solid $familyEventColor;
    }

    &.Automatic {
      background-color: $automaticEventColor;
      border: 1px solid black;
    }
  }

  .fc-list-item {
    .fc-event-dot {
      background-color: $defaultEventColor;
    }

    &.Compliance {
      .fc-event-dot {
        background-color: $complianceEventColor;
      }
    }

    &.OnDemand {
      .fc-event-dot {
        background-color: $onDemandEventColor;
      }
    }

    &.Seniors {
      .fc-event-dot {
        background-color: $seniorsEventColor;
      }
    }

    &.Staff {
      .fc-event-dot {
        background-color: $staffEventColor;
      }
    }

    &.Family {
      .fc-event-dot {
        background-color: $familyEventColor;
      }
    }

    &.Automatic {
      .fc-event-dot {
        background-color: $automaticEventColor;
      }
    }
  }
  thead {
    .fc-scroller-harness {
      .fc-scroller {
        overflow: hidden !important;
      }
    }
  }
}

.calendar-key {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;

  .color-key {
    &.emergency {
      .form-check-input:checked:after {
        background-color: $emergencyEventColor;
      }
    }

    &.seniors {
      .form-check-input:checked:after {
        background-color: $seniorsEventColor;
      }
    }

    &.staff {
      .form-check-input:checked:after {
        background-color: $staffEventColor;
      }
    }

    &.family {
      .form-check-input:checked:after {
        background-color: $familyEventColor;
      }
    }
    &.compliance {
      .form-check-input:checked:after {
        background-color: $complianceEventColor;
      }
    }
    &.ondemand {
      .form-check-input:checked:after {
        background-color: $onDemandEventColor;
      }
    }
    &.automatic {
      .form-check-input:checked:after {
        background-color: $automaticEventColor;
      }
    }
  }
}

.Calendar.mobile {
  .fc-view-container {
    min-height: 25px;
  }

  .calendar-key {
    flex-wrap: wrap;

    .label {
      flex-basis: 100%;
    }

    .color-key {
      flex-basis: 50%;
    }
  }

  .calendar-navigation {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 1rem;

    .button-group {
      margin-right: 0;
    }
  }
}

.calendar-event-menu {
  position: fixed;
  z-index: 1;
  background: white;
  list-style: none;
  border: 1px solid $vfGray;
  padding: 0;

  li {
    border: 1px solid transparent;
    padding: 0px;
    margin: 0px;
    a,
    button {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0.5rem !important;
      text-align: left;
    }
    &:hover {
      border: 1px solid $blue;
    }
  }
}
