.refferal {
  &__box {
    background: $white;
    box-shadow: 0px 3px 6px #00000066;
    border-radius: 6px;
    height: 305px;
    text-align: center;
    margin-bottom: 13px;
    padding: 40px 40px 0;
    width: 386px;
    max-width: 100%;

    &__heading {
      letter-spacing: 0.06px;
      line-height: 26px;
    }

    &__paragraph {
      letter-spacing: 0.05px;
      line-height: 25px;
    }

    &__button {
      border-radius: 9px;
      width: 100%;
    }
  }

  &__terms {
    font-size: 14px;
    margin-bottom: 430px;

    @media only screen and (max-height: 1074px) {
      margin-bottom: calc(8vh + 285px);
    }
  }
}
