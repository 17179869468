.entitylabel {
    max-width: 100%;
    display: inline-flex;
    background: $blue-500;
    color: $blue;
    border-radius: 30px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    align-items: center;
    overflow: hidden;
    padding: 0px 12px;
}

.entitylabel-text {
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;

    // workaround to prevent Safari from showing tooltip when text overflow is hidden with ellipsis
    &::after {
        content: '';
        display: block;
      }
}

.entity-button {
    min-width: 50px!important;
}

.entity-delete-icon {
    margin-left: 10px;
    cursor: pointer;
    color: $blue;
}

.label-container {
    height: 40px;
    max-width: 300px;
}