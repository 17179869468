.CalendarIndex {
  .alert {
    margin-bottom: 2rem;
  }

  .CalendarFilter {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    flex-wrap: wrap;

    .form-check + .form-check {
      margin-left: 1rem;
    }
  }
}
