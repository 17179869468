.template-send-now-modal {
    .modal-content {
        padding: 24px 24px 24px 32px;
        width: 800px;
    }

    .modal-header {
        align-items: center;
        justify-content: left;
    }

    .modal-title {
        font-size: 28px;
        font-weight: 600;
        width: 100%;
    }

    .div-title-name {
        line-height: 2rem;
        margin-left: 12px;
    }
 
    .modal-body {
        padding: 15px 0 15px 0;
    }

    .modal-footer {
        padding: 0;
        padding-top: 28px;
    }

    .div-recipients {
        max-height: 210px;
        overflow: auto;
    }

    .btn-send {
        & > .btn:not(.dropdown-toggle) {
            min-width: 0;
        }

        & > .dropdown-menu .dropdown-item {
            color: $red-700;
        }
    }

    .wrapword {
        font-family: sans-serif;
        font-size: 1rem;
        white-space: pre-wrap;
    }
}