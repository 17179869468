.languageSelection {
  min-width: 13rem;
  margin-left: auto;
}

.inputLanguageLabel {
  margin-bottom: 0rem;
}

.commPrefCol {
  display: flex;
  margin-bottom: 10px;
}
