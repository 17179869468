.alert {
  border-radius: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  p:last-of-type {
    margin-bottom: 0;
  }

  &.alert-success {
    background-color: #15B375;
    color: white;
    
    div, span, p {
      color: white;
    }
  }

  &.alert-warning {
    background-color: #FFD754;
  }

  &.alert-danger {
    background-color: #FF7052;
  }

  &.alert-info {
    background-color: #2D9CCC;
    color: white;

    div, span, p {
      color: white;
    }
  }
}
