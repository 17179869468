.btn.right{
    margin-left: .5rem;
}

.btn.left{
    margin-left: .5rem;
}

.discharged-patients div.react-tags{
    padding-top: 0px;
    border: 0;
    border-radius: 6px;
}

div.col.updateStatusSelect{
    padding:0px;
}

div.col.updateStatusSelect:nth-child(1){
    padding-right:15px;
}

div.col.updateStatusSelect:nth-child(2){
    padding-left:15px;
}

div.row.status-and-tags{
    margin-left:0px;
    margin-right:0px;
}

div.info-row div.section-header{
    padding:0px;
}