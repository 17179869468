.modal {
  .modal-content {
    padding: 17px;
  }

  .modal-header {
    padding: 0;
    border-bottom: none;

    .close {
      opacity: 1;
      padding: 0;
      margin: 0 0 0 auto;

      & > span {
        color: $vfBlue;
        font-size: 1.7rem;
      }
    }
  }

  .modal-body {
    padding: 0 15px;

    &.modal-logo {
      &::before {
        content: '';
        display: none;
        width: 160px;
        height: 24px;
        margin: 0 auto 25px;
    
        @include media-breakpoint-down(xs) {
          display: block;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .modal-dialog {
      max-width: 100%;
      height: 100%;
      margin: 0;

      .modal-content {
        border-radius: 0;
        height: 100%;
      }
    }
  }
}

.modal-backdrop ~ .modal-backdrop {
  z-index: 1050;

  & ~ .modal-backdrop {
    z-index: 1060;
  }
}

.modal ~ .modal {
  z-index: 1060;

  & ~ .modal {
    z-index: 1070;
  }
}