.log-entry {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.log-entry-tooltip > .tooltip-inner {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}