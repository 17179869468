.group-recipients {
  overflow-y: scroll;
  overflow-x: hidden;;
  max-height: 600px;
  line-height: 0.7rem;
  border-radius: 0px!important;
}
.group-recipients-header {
  line-height: 0.7rem;
  border-radius: 0px!important;
}
.col-PaddingLeft-0{
  padding-left: 0px !important;
  padding-top: 1px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MessageRecipientsCol {
  .remove-recipient-btn {
    border: 0;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2px;
  }
}