.UserMenu {
  position: relative;

  &--Modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .user-info {
      text-align: center;
    }

    .email {
      font-size: 12px;
      margin-top: 0.5rem;
      color: $vfLinkNav;
    }

    .avatar {
      // width: 60px;
      // height: 60px;
      border-radius: 100%;
      background-color: $vfBlue;
      margin-bottom: 0.5rem;
    }

    .btn-link, .links {
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      font-size: 16px;
      color: $vfBlue;
      width: 100%;
      margin-top: 1rem;

      button, 
      a,
      span {
        display: block;
        color: $vfBlue;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      li {
        padding: 0.5rem 0;
        
        & + li {
          border-top: 1px solid rgba($vfFunctionalGray, 0.25);
        }
      }

      @include media-breakpoint-down(xs) {
        min-width: 80%;
      }
    }

    .Footer {
      margin-top: 4rem;

      a {
        display: none;
      }
    }

    @media all and (max-width: 576px) {
      height: 100%;

      .logo {
        margin-bottom: auto;
      }

      .user-info,
      .links {
        margin: auto 0;
      }
      
      .Footer {
        margin-top: auto;
      }
    }
  }
}
