.sms-auto-reply-caption {
    font-size: 0.75rem;
    color: #9597A6;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.sms-auto-reply-text {
    label {
        font-size: 0.75rem;
        color: #9597A6!important;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
    }
}
