.VerticalNav {
  width: 100%;

  .label {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;

    li {
      width: 100%;
      margin: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      position: relative;

      &:last-of-type {
        border-bottom: none;
      }

      .title {
        height: 50.8px;
      }

      &:hover {
        cursor: pointer;

        .title {
          

          .text {
            text-decoration: underline;
          }
        }
      }

      a {
        height: 50.8px;
        padding: 0 25px;
        text-decoration: none;

        &:hover {
          text-decoration: none;

          .text {
            text-decoration: $link-hover-decoration;
          }
        }

        &.active {
          background: $vfLinkActiveNav;
          color: $white !important;
        }
      }

      a,
      span.title {
        display: flex;
        width: 100%;
        padding: 15px;
        line-height: 1.3;
        color: $vfLinkNav;
        user-select: none;

        svg {
          margin-right: 10px;
          max-width: 1.25rem;
          width: 1.25rem !important;
        }
      }

      .subnav {
        margin-bottom: 0.5rem;
        display: none;

        li {
          border-bottom: 0;
          text-align: left;

          a {
            height: 36.8px;
            padding: 0.5rem 0 0.5rem 3rem;
          }
        }

        &.open {
          display: block;
        }

        a {
          padding: 0;
        }
      }

      .subnav-toggle {
        position: absolute;
        top: 0.8rem;
        right: 0.6rem;
        color: $vfLinkNav;
        width: 25px;
        text-align: right;
      }
    }
  }
}
