.navbar-light {
  .navbar-nav {
    .nav-item {
      border-right: 1px solid rgba($gray-600, 0.25);
  
      &:last-child {
        border-right: none;
      }
    }
    
    .nav-link {
      font-weight: bold;
    }
  }
}

.side-nav {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-height: 100%;
  background: $primary;
  padding: 0 50px;
}
